<div class="sidenav d-flex flex-column h-100">
    <div class="flex-grow-1">
        <a mat-button (click)="openViplanLicences()">{{ 'rightMenu.oslicenses' | translate }}</a>
    </div>
    <div class="version-footer flex-grow-0">
        <div class="version">
            <strong>{{ 'rightMenu.appName' | translate }}</strong>
            <span>{{ 'rightMenu.version' | translate: versionParam }}</span>
        </div>
        <div class="company-details">
            VIESSMANN CLIMATE SOLUTIONS SE - A CARRIER COMPANY - ©️ {{ currentYear }}
            <a class="carrier-link" href="https://www.corporate.carrier.com/" target="_blank">CARRIER</a>
        </div>
    </div>
</div>
